import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/less/reset.less";

Vue.config.productionTip = false;

function setRem() {
  const screenWidth = 1920;
  const scale = screenWidth / 192;
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;

  // 得到html的Dom元素
  const htmlDom = document.getElementsByTagName("html")[0];
  // 设置根元素字体大小
  if (htmlWidth > 1920) {
    htmlWidth = 1920;
  }
  htmlDom.style.fontSize = htmlWidth / scale + "px";
}

//全局路由守护
// router.beforeEach((to, from, next) => {
//   setRem();
//   next();
// });
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
