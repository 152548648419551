<template>
  <div class="container">
    <div class="top">
      <div class="privacy" @click="goPrivacy">Privacy Policy</div>
     <div class="privacy" @click="goAgreement">End User License Agreement</div>
    </div>
   
    <div class="title1 text">IFLYTEK (HONG KONG) COMPANY LIMITED</div>
    <!-- <div class="title2 text">ENTERTAIN THE WORLD WITH AWESOME GAMES</div> -->
    <div class="title3 text">Add: ROOM B10/F.TOWER ABILLION CENTRE，1 WANG KWONGROAD，KOWLOON BAYKOWLOONHONG KONG <br /> Email: publisher@xunfgame.com </div>
  </div>
</template>
<script>
export default {
  name: "",
  data () {
    return {

    }
  },
  created () {

  },
  methods: {
    goPrivacy(){
      this.$router.push( "/privacy");

    },
    goAgreement(){
      this.$router.push( "/agreement");

    },
    
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 100vw;
  position: relative;
  height: 100vh;
  padding-top: 300px;
  background: #3d3ee7;
  overflow: hidden;

  .top{
    position: absolute;
    top: 30px;
    right: 50px;
     color: #fff;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    
    div{
      margin-left: 40px;
      font-weight: 700;
    }
  }

  .text {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
  
  }

  .title1{
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  .title2{
    font-size: 38px;
    font-weight: 400;
  }

  .title3{
    position: absolute;
    left: 0;
    bottom: 100px;
  }

}
</style>